// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-home-page-index-js": () => import("/opt/build/repo/src/components/templates/home_page/index.js" /* webpackChunkName: "component---src-components-templates-home-page-index-js" */),
  "component---src-components-templates-portfolio-index-js": () => import("/opt/build/repo/src/components/templates/portfolio/index.js" /* webpackChunkName: "component---src-components-templates-portfolio-index-js" */),
  "component---src-components-templates-testimonials-index-js": () => import("/opt/build/repo/src/components/templates/testimonials/index.js" /* webpackChunkName: "component---src-components-templates-testimonials-index-js" */),
  "component---src-components-templates-contact-us-index-js": () => import("/opt/build/repo/src/components/templates/contact_us/index.js" /* webpackChunkName: "component---src-components-templates-contact-us-index-js" */),
  "component---src-components-templates-service-index-js": () => import("/opt/build/repo/src/components/templates/service/index.js" /* webpackChunkName: "component---src-components-templates-service-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

